function toggleMobileMenu() {
	$('#header').toggleClass('mobileToggled');
}

function scrollToElement(element, offset = 0) {
	$('html, body').animate({
	    scrollTop: $(element + ':visible:first').offset().top - offset
	}, 500);
}

function scrollToContent() {
    $('html, body').animate({
        scrollTop: $('.content:visible:first').offset().top - 50
    }, 500);
}

function checkCookieAcceptance() {
    if (localStorage.getItem("cookies")) {
        $('#cookies').css("display", "none");
    }
};

 function acceptCookies() {
    $('#cookies').animate({'height': 0});
    localStorage.setItem("cookies", true);
};

$( document ).ready(function() {
    checkCookieAcceptance();

    var buttonUpShown = 0;
    var scroll = $(window).scrollTop();

    if (scroll > 500) {
        if (buttonUpShown === 0) {
            $(".back-up").addClass("show");
            buttonUpShown = 1;
        }
    } else {
        if (buttonUpShown === 1) {
            $(".back-up").removeClass("show");
            buttonUpShown = 0;
        }
    }

    $(window).scroll(function () {
        var scroll = $(window).scrollTop();

        if (scroll > 500) {
            if (buttonUpShown === 0) {
                $(".back-up").addClass("show");
                buttonUpShown = 1;
            }
        } else {
            if (buttonUpShown === 1) {
                $(".back-up").removeClass("show");
                buttonUpShown = 0;
            }
        }
    });

});

function toggleForm() {
    $("#rezervace").toggleClass('open');
}

function toggleForm2() {
    $("#personal").toggleClass('open');
}

function sendContactForm() {

    url = "/kontaktni-formular";

    $.ajax({
        type: 'POST',
        dataType: 'json',
        url: url,
        timeout: 5000,
        data: $('form#contact-form').serialize(),
    }).done(function () {
        $('#contact-form')[0].reset();
        toggleForm();
        alert('Zpráva úspěšně odeslána, co nejdříve se Vám ozveme.');
        
    }).fail(function(response) {
        if (response.status === 400) {
            alert('Je nám líto, nastala chyba při odesílání.\nZkontrolujte zadané údaje, nebo nás kontaktujte na info@stavounion.cz');
        } else {
            alert('Je nám líto, nastala chyba při odesílání.\nZkuste to prosím později, nebo nás kontaktujte na info@stavounion.cz');
        }
    });

    return false;
}